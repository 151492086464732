import React from "react";

export default function NotFound() {
  if (!globalThis.window) return null;
  const newUrl = new URL(
    `${location.protocol}\/\/${location.host}?from=${location.pathname.slice(
      1
    )}`
  );
  new URL(location.href).searchParams.forEach(([value, key]) =>
    newUrl.searchParams.append(key, value)
  );
  location.href = newUrl.href;
  return null;
}
